import React from "react";

import { db , auth} from "../firebase";

import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Modal, ModalBody , Button} from "react-bootstrap";


import DatePicker from 'react-datepicker';



function topFiveMostRepeatedNumbers(arr) {
  const count = {};

  // Count the occurrences of each number
  for (const num of arr) {
      count[num] = (count[num] || 0) + 1;
  }

  // Convert the count object into an array of [number, count] pairs
  const countArray = Object.entries(count);

  // Sort the array by count in descending order
  countArray.sort((a, b) => b[1] - a[1]);

  // Get the top 5 most repeated numbers
  const topFive = countArray.slice(0, 5).map(([num]) => Number(num));

  return topFive;
}



function extractValues(arr, key) {
  return arr.map(item => Number(item[key]));
}


function mostRepeatedNumber(arr) {
  const count = {};
  let maxCount = 0;
  let mostRepeated = null;

  for (const num of arr) {
      // Increment the count for the number
      count[num] = (count[num] || 0) + 1;

      // Check if this number is the most repeated so far
      if (count[num] > maxCount) {
          maxCount = count[num];
          mostRepeated = num;
      }
  }

  return mostRepeated;
}

// Example usage
// const largeArray = [1, 3, 2, 4, 3, 5, 3, 1, 2, 4, 1, 3, 5];
// const result = mostRepeatedNumber(largeArray);
// console.log(result); // Output: 3


function bottomFiveLeastRepeatedNumbers(arr) {
  const count = {};

  // Count the occurrences of each number
  for (const num of arr) {
      count[num] = (count[num] || 0) + 1;
  }

  // Convert the count object into an array of [number, count] pairs
  const countArray = Object.entries(count);

  // Sort the array by count in ascending order
  countArray.sort((a, b) => a[1] - b[1]);

  // Get the top 5 least repeated numbers
  const bottomFive = countArray.slice(0,5).map(([num]) => Number(num));

  return bottomFive;
}

function countOccurrencesAndPercentage(arr, target) {
  console.log(arr, target)
  const totalCount = arr.length;
  const targetCount = arr.filter(num => num === target).length;

  // Calculate the percentage
  const percentage = totalCount > 0 ? (targetCount / totalCount) * 100 : 0;

  return {
      count: targetCount,
      percentage: percentage.toFixed(2) // Format to 2 decimal places
  };
}

function getLastAppearance(arr, target) {
  // Find the last index of the target number
  let lastIndex = arr.lastIndexOf(target);

    // If the number is not in the array, return a message saying so
    if (lastIndex === -1) {
        return `NaN`;
    }

    // Traverse from the beginning of the array up to lastIndex to find the first occurrence
    for (let i = 0; i <= lastIndex; i++) {
        if (arr[i] === target) {
            return i + 1;
        }
    }

  // If the number is not in the array, return a message saying so
  
  // Return the last index if found
}


function countOccurrencesAndPercentageNum(arr, target) {
  console.log(arr, target)
  const totalCount = arr.length;
  const targetCount = arr.filter(num => num === target).length;

  // Calculate the percentage
  const percentage = totalCount > 0 ? (targetCount / totalCount) * 100 : 0;

  return <span className="text-center small"><small className="text-muted text-center w-100">{targetCount}/{totalCount}</small><br/>   {  percentage.toFixed(2) }%</span>;
  }

class AllSpins extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
      loading:true,
      nums:[],
      numbers:[],
      selectedNumber:0,
      selectedView: 200,
      subscribed:true,
      show: false,
      selectedDate: new Date(),
      lastNumbers:[],
    }
  } 


  componentDidMount() {

    var id = this.props.match.params.id;
    const nameTable = this.props.match.params["name"];

    this.setState({nameTable})
    if(id) {
        this.getNumbers(200)
        this.setState({id})
        this.getLast()
        this.getUser();
    }
  }

  
  async getLast() {
    var id = this.props.match.params.id;
    await db.collection('tables').doc(id).collection("entries").orderBy("id","desc").onSnapshot((snapshot) => {
      const postData = [];
      snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
   

      const nums = extractValues(postData, 'result');
      this.setState({lastNumbers:nums})
    })
  }


  

  getUser = async () => {

    if(auth.currentUser) {
      db.collection('users').doc(auth.currentUser.email).get()
      .then(doc => {
          const data = doc.data();
          this.setState({user:data})
          if(data.subscribed === true) {
              this.setState({ subscribed: true})
          } else {

              this.setState({ subscribed: false})
          }
      
      })

    } else {
      this.setState({ subscribed: false})
    }
  }



  addNum(newItem) {
    this.setState({ nums: [...this.state.nums, newItem] })
  }

  async getNumbers(numAmount) {
    const id = this.props.match.params.id; 
    await db.collection('tables').doc(id).collection("entries").limit(numAmount).orderBy("id","desc").onSnapshot((snapshot) => {
      const postData = [];
      snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
      this.setState({numbers:postData})
    })

    setTimeout(() => {
      // Function to extract a specific value
   
    // Top five
      const nums = extractValues(this.state.numbers, 'result');

      this.setState({nums})
     
      var topFive =  topFiveMostRepeatedNumbers(nums)
      var bottomFive =  bottomFiveLeastRepeatedNumbers(nums)
      var topNum =  mostRepeatedNumber(nums)


      this.setState({topFive})
      this.setState({bottomFive})

      
      
      
      console.log("Top 5: ", JSON.stringify(topFive))


      console.log("Bottom 5: ", JSON.stringify(bottomFive))


      console.log("Top 1: ", topNum)



      this.selectNumber(nums[0],nums)


    }, 1500);
     

  }



  renderNums() {
    return (
      <>
      {this.state.lastNumbers?.map(data => (
          this.renderNum(data)
          
      ))} 
      {JSON.stringify(this.state.lastNumbers)}
      </>
    )
   
  }


  numberStads() {
    const numbers = Array.from({ length: 37 }, (_, i) => i);
    
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }} >
        {numbers.map((number) => (
          <div className="text-center justify-content-center align-items-center">
           { this.renderNum(number)}
           {countOccurrencesAndPercentageNum(this.state.nums, number)}
      
           <br/>
           <small className="text-muted">Last {getLastAppearance(this.state.nums, number)}</small>
           {/* {getLastA  ppearance(this.state.nums,number)} */}
          </div>
        ))}
      </div>
    );
  

  }

 

  renderNum(item) {

    if (item === 0 || item === "00") {
      return  <div
      onClick={() => this.selectNumber(item, this.state.nums)}
      onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
      className={ "badge-num bg-success border-0 col-1 btn-sm btn-danger btn text-light"}>{item}</div>
    }
    
    const redNumbers = [
      1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36
    ];
    
    return redNumbers.includes(item) ? <>
    <div 
    onClick={() =>  this.selectNumber(item, this.state.nums)}
    onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
    className="badge-num bg-danger col-1 btn-sm btn-danger btn text-light">{item}</div>
    </> 
     :  <div 
     onClick={() =>  this.selectNumber(item, this.state.nums)}
     onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
     className="badge-num col-1 btn-sm bg-dark  btn text-light">{item}</div>;


  }

  renderNumBig(item) {

    if (item === 0 || item === "00") {
      return   <div 
      onClick={() =>  this.selectNumber(item, this.state.nums)}
      onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
      className="cursor col-2 m-1 m-lg-1 m-md-2 numberBig d-flex justify-content-center align-items-center border rounded bg-success text-white ">{item}</div>
    }
    
    const redNumbers = [
      1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36
    ];
    
    return redNumbers.includes(item) ? <>
    <div 
      onClick={() =>  this.selectNumber(item, this.state.nums)}
      onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
      className="cursor col-2  m-1  m-lg-1 m-md-2 numberBig d-flex justify-content-center align-items-center border rounded bg-danger text-white"> {item}</div>
    </> 
     :  <div 
     onClick={() =>  this.selectNumber(item, this.state.nums)}
     onMouseEnter={() =>  this.selectNumber(item, this.state.nums)}
     className="cursor col-2  m-1  m-lg-1 m-md-2  numberBig d-flex justify-content-center align-items-center border rounded bg-dark text-white"> {item}</div>;

  }

  async selectNumber(num,arr) {

    var dozen;
    var type;

    if(num == 0) {
      type = "Zero"
    }
    else if(num % 2 == 0) {
       type = "Even"
    }
    else {
      type= "Odd"
  
    }
      if (num === 0) {
           dozen= "Zero";
      } else if (num >= 1 && num <= 12) {
          dozen= "1-12";
      } else if (num >= 13 && num <= 24) {
          dozen= "13-24";
      } else if (num >= 25 && num <= 36) {
        dozen = "25-36"
      }

      var data =  await countOccurrencesAndPercentage(arr,num);
      console.log(data)
      
      
      this.setState({selectedNumber: {
        number: num,
        type,
        dozen,
      }})

      this.setState({percentage: data.percentage,count: data.count })
  }

  changeView(num) {
    if(num === 1000) {
      this.getNumbers(1000)
      this.setState({selectedView: "24H"});
    } else if(num == 200) {
      this.getNumbers(200)
      this.setState({selectedView: "200"});
    } else if(num== 2000) {
      this.getNumbers(2000)
      this.setState({selectedView: "48H"});

    }
    else if(num== 3500) {
      this.getNumbers(3500)
      this.setState({selectedView: "72H"});

    }
    else if(num== 7000) {
      this.getNumbers(7000)
      this.setState({selectedView: "144H"});

    } if(num == 1) {
      
      this.getNumbers(100)
      this.setState({show:false, selectedView: 1})
    } 
    
    else if (num === 1001){
      this.getNumbers(41000)
      this.setState({selectedView: "All time"});

    }
  }


  // day picker


  render() {

      return (
		    <div>

        <div className="container pb-2 mt-2">
        <nav className="bg-transparent" >
        <ol className="breadcrumb">
          <li className="breadcrumb-item text-white"><a className=" text-white" href="/">Home</a></li>
          <li  className="breadcrumb-item text-dark">    <Link  className=" text-white"  to="/tables">Roulette</Link></li>
          <li className="breadcrumb-item active" aria-current="page">{ this.state.nameTable }</li>

        </ol>
      </nav>

      <div className="d-flex align-items-center">
        <h4>{this.state.nameTable}</h4>
      </div>     

          <div className="row mt-3">
    
            {/* Tabs content */}
            <div className="col-12 col-lg-12 ">

            <div className=" row border-1 p-2 ">
                <h5>History of rounds</h5>
                <p>All spins</p>
                <br/>


                {this.renderNums()}
            


              </div>
              </div>

            
            </div>
          </div>
        
       
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="container">
            <h4>Statistics and real-time roulette tracking</h4>
              In this section, players are given a unique opportunity to follow live statistics of roulette games from leading developers such as Evolution, Ezugi, Pragmatic, and Playtech.

              Statistics include a variety of data, such as the frequency of numbers, colors, dozens, neighbors on the roulette wheel, sectors, and much more. The tracker interface ensures transparency and openness, allowing players to make more informed decisions based on the provided information.

              Such a tracker is a useful tool for analyzing trends and patterns in games, which can help players develop their strategies and increase their chances of success. All this creates an engaging and interactive experience for gambling enthusiasts, providing them with access to important real-time data.
              <br/>
            <br/>
            <br/>
          
            </div>

		</div>

      
      );

    }
  }


export default AllSpins;
